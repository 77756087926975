import './Login.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../Helper/apiService'; // Adjust the import path as necessary

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook to programmatically navigate

  // Check local storage on component mount
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn'); // Adjust this key based on your implementation
    
    if (isLoggedIn==='true') {
      //alert(isLoggedIn)
      navigate('/home'); // Redirect to home if logged in
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    var obj = { username, password };
    try {
      const data = await apiService.POST(obj);
      // Handle successful login
      console.log('Login successful', data);
      localStorage.setItem('isLoggedIn', 'true'); // Set local storage value
      navigate('/home'); // Redirect to home after login
    } catch (error) {
      console.error(error); // Log error for debugging
      setError(error.message || 'An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label>Password:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
