import React from 'react';

import SideMenu from '../Home/SideMenu';
const ContactUs = () => {
    return (
      <div>
      <SideMenu/>
        <div style={styles.container}>
            {/* <h2>Contact Us</h2> */}
            <div style={styles.contactInfo}>
                <p style={styles.infoItem}><strong>Phone:</strong> +91 7845623287</p>
                <p style={styles.infoItem}><strong>Email:</strong> marssupport@mars.com</p>
                <p style={styles.infoItem}><strong>Address:</strong> 58A, Maruthuvar Street, Kayalpatnam, Tuticorin</p>
            </div>
        </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    contactInfo: {
        marginTop: '20px',
        fontSize: '16px',
    },
    infoItem: {
        marginBottom: '10px',
    },
};

export default ContactUs;
