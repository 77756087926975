import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles.css';

function SideMenu() {
  const [menuItems, setMenuItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [activeMenuName, setActiveMenuName] = useState('Home');

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn !== 'true') {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    fetch('/menu.json')
      .then(response => response.json())
      .then(data => {setMenuItems(data)
        // Get active menu name from local storage
        const storedMenuName = localStorage.getItem('activeMenuName');
        if (storedMenuName) {
          setActiveMenuName(storedMenuName); // Use stored value if exists
        } else if (data.length > 0) {
          setActiveMenuName(data[0].title); // Default to the first item
        }
      }
    
    )
      .catch(error => console.error('Error fetching menu:', error));
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (item) => {
    if (item.link === "logout") {
      localStorage.setItem('isLoggedIn', 'false');
      navigate('/login');
    } else {
      navigate(item.link);
      setActiveMenuName(item.title); // Update the active menu name
      localStorage.setItem('activeMenuName', item.title);
    }
    toggleMenu();
  };
  const logOutClick = () => {
    localStorage.setItem('isLoggedIn', 'false'); // Set isLoggedIn to false
    navigate('/login'); // Redirect to login page
  };
  return (
    <div className="side-menu-container" >
      <button className="openbtn" onClick={toggleMenu}>&#9776; </button>
      <div className='menuname' >{activeMenuName}</div>
      <div className={`sidenav ${isOpen ? 'open' : ''}`}>
        <button className="closebtn" onClick={toggleMenu}>&times;</button>
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            onClick={() => handleLinkClick(item)}
            className="menu-item"
          >
            <i className={`icon ${item.icon}`}></i>
            <span className="menu-text">{item.title}</span>
          </Link>
        ))}
      </div>
      <div>
        
        
        <button className="logout-btn" onClick={logOutClick}><i className='fa fa-sign-out-alt'></i> Logout
            
            </button></div>
      
    </div>
  );
}

export default SideMenu;
