import React, { useEffect } from 'react';
import SideMenu from './SideMenu';
import './styles.css';  // Assuming your styles include side menu and layout


function Home() {

  useEffect(() => {
localStorage.setItem('activeMenuName', 'Home');
}, []);
  return (
      <div>
        <SideMenu />

        <div style={{ marginLeft: '250px', padding: '20px' }}>
          
        </div>
      </div>
  );
}

export default Home;
