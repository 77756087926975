import React from 'react';

import SideMenu from '../Home/SideMenu';
const AboutUs = () => {
    return (
      <div>
         <SideMenu/>
        <div style={styles.container}>
            {/* <h2>About Us</h2> */}
            <p style={styles.paragraph}>
                Welcome to MARS IT Solutions! We are dedicated to providing the best services 
                and products to our customers. Our mission is to deliver high-quality solutions 
                that meet the needs of our clients while fostering innovation and sustainability.
            </p>
            <h3>Our Mission</h3>
            <p style={styles.paragraph}>
                Our mission is to empower individuals and businesses by offering exceptional 
                services and cutting-edge solutions that drive success.
            </p>
            <h3>Our Values</h3>
            <ul style={styles.list}>
                <li>Integrity: We conduct our business with honesty and transparency.</li>
                <li>Customer Focus: Our customers are at the heart of everything we do.</li>
                <li>Innovation: We embrace creativity and change to improve our services.</li>
                <li>Sustainability: We are committed to making a positive impact on the environment.</li>
            </ul>
        </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    paragraph: {
        marginBottom: '15px',
        fontSize: '16px',
        lineHeight: '1.5',
    },
    list: {
        listStyleType: 'disc',
        paddingLeft: '20px',
    },
};

export default AboutUs;
