// apiService.js
const API_URL = 'http://127.0.0.1:5000'; // Use an environment variable in production

const apiService = {
  POST: async (obj) => {
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to service');
    }

    return response.json();
  },
  // You can add more API methods here
};

export default apiService;
